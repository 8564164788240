import React from "react";
import { FormattedMessage } from "react-intl";
import { Popup, PopupButtons, PopupContent, PopupTitle } from ".";
import { Button } from "../../button/Button";
import { Text } from "../../typography/Text";

export const PopupYesNo = ({ title, children, busy, onYes, onNo }) => {
    return (
        <Popup onClose={onNo}>
            <PopupTitle icon="question-circle">{title}</PopupTitle>
            <PopupContent>
                <Text size="medium">{children}</Text>
            </PopupContent>
            <PopupButtons>
                <Button name="yes" icon="check" iconColor="success" onClick={onYes} busy={busy}>
                    <FormattedMessage id="popupYesNo.yes" />
                </Button>
                <Button name="no" icon="close" iconColor="error" onClick={onNo} disabled={busy}>
                    <FormattedMessage id="popupYesNo.no" />
                </Button>
            </PopupButtons>
        </Popup>
    );
};
