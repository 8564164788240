import React from "react";
import classNames from "classnames";
import styles from "./PopupTitle.scss";
import { Icon } from "../../graphic/Icon";
import Image from "../../media/Image";

export const PopupTitle = ({ icon, image, subtitle, size, align, type, children }) => {
    return (
        <div
            className={classNames(styles.base, {
                [styles.typeError]: type === "error",
                [styles.sizeMedium]: size === "medium",
                [styles.alignCenter]: align === "center"
            })}
        >
            {image ? (
                <div className={styles.image}>
                    <Image src={image} size="fit" lightbox />
                </div>
            ) : icon ? (
                <div className={styles.icon}>
                    <Icon name={icon} />
                </div>
            ) : null}
            <div className={styles.titles}>
                <div className={styles.title}>{children}</div>
                {subtitle ? <div className={styles.subtitle}>{subtitle}</div> : null}
            </div>
        </div>
    );
};
