import React from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "../../button/Button";
import { Text } from "../../typography/Text";
import { Popup, PopupButtons, PopupContent, PopupTitle } from "../Popup";

const msgKey = "popupError";

export const PopupError = ({ title, message, onClose }) => {
    return (
        <Popup onClose={onClose} type="error">
            <PopupTitle icon="exclamation-circle">
                {title || <FormattedMessage id={`${msgKey}.defaultTitle`} />}
            </PopupTitle>
            <PopupContent>
                <Text size="medium">{message || <FormattedMessage id={`${msgKey}.defaultMessage`} />}</Text>
            </PopupContent>
            <PopupButtons>
                <Button onClick={onClose}>
                    <FormattedMessage id={`${msgKey}.ok`} />
                </Button>
            </PopupButtons>
        </Popup>
    );
};
