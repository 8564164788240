import React from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "../../button/Button";
import Col from "../../grid/Col";
import Row from "../../grid/Row";
import { Popup, PopupButtons, PopupContent, PopupTitle } from "../../notify/Popup";
import { Text } from "../../typography/Text";

const msgKey = "newVersionReloadPrompt";

export const NewVersionReloadPrompt = ({ onDismiss }) => {
    return (
        <Popup>
            <PopupTitle icon="exclamation-circle">
                <FormattedMessage id={`${msgKey}.title`} />
            </PopupTitle>
            <PopupContent>
                <Row>
                    <Col span={24}>
                        <Text size="medium" weight="semiBold">
                            <FormattedMessage id={`${msgKey}.newVersionMessage`} />
                        </Text>
                    </Col>
                    <Col span={24}>
                        <Text color="base80">
                            <FormattedMessage id={`${msgKey}.unsavedWorkMessage`} />
                        </Text>
                    </Col>
                </Row>
            </PopupContent>
            <PopupButtons>
                <Button iconColor="primary" icon="refresh" onClick={() => window.location.reload()}>
                    <FormattedMessage id={`${msgKey}.reload`} />
                </Button>
                <Button onClick={onDismiss} color="silent">
                    <FormattedMessage id={`${msgKey}.close`} />
                </Button>
            </PopupButtons>
        </Popup>
    );
};
